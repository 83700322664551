@import "scss/global/base/variables.scss";

.RegistrationAgeGender {
	&__title {
		display: block;
		font-weight: 600;
		margin-bottom: $space-smaller;
	}
	&__age,
	&__gender {
		display: flex;
		flex-wrap: wrap;
		gap: $space-smaller;
		margin-bottom: $space-small;
	}
}