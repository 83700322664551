@import "scss/global/base/variables";

.TagButton {
	padding: $space-smaller $space-small;
	color: $font-secondary;
	font-weight: 600;
	font-size: $font-size-ssmall;
	border: 1px solid $font-secondary;
	border-radius: 25px;
	outline: 0;
	.icon {
		&::before,
		&::after {
			color: $font-secondary;
		}
	}
	&__emote {
		width: 28px;
		height: 28px;
	}
	&.is-active {
		color: white;
		background: $font-secondary;
		.icon {
			&::before,
			&::after {
				color: white;
			}
		}
	}
	&.is-emote {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: $space-smaller;
		padding: $space-smallest $space-smaller;
	}
}
