@import "scss/global/base/variables.scss";

.AuthLayout {
	$root: &;

	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	@media screen and (min-width:$medium-break) {
		width: 505px;
		height: auto;
	}

	&__header {
		padding: $space-external $space-external calc(#{$space-external} + 200px);
		background: url('~img/user-profile-cover.svg') no-repeat center center;
		background-size: cover;
		position: relative;
		&__top {
			display: flex;
			align-items: center;
			gap: $space-small;
			margin-bottom: $space-large;
			@media screen and (min-width:$medium-break) {
				max-width: 600px;
				margin-left: auto;
				margin-right: auto;
			}
			.title {
				color: white;
				font-size: $font-size-medium;
			}
		}
		&__bottom {
			margin: 0 auto;
			color: white;
			@media screen and (min-width:$medium-break) {
				max-width: 600px;
				margin-left: auto;
				margin-right: auto;
			}
			.title {
				font-size: $font-size-xlarge;
				margin-bottom: $space-smaller;
			}
			.description {
				font-size: $font-size-small;
			}
		}
	}
	&__main {
		flex: 1;
		margin-top: -200px;
		padding: $space-external;
		background: #f9f9f9;
		border-top-left-radius: $border-radius-medium;
		border-top-right-radius: $border-radius-medium;
		position: relative;

		@media screen and (min-width:$medium-break) {
			background: white;
			box-shadow: $box-shadow;
			border-radius: $border-radius-big;
		}
	}
}