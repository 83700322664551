@import "scss/global/base/variables.scss";

.RegistrationWizard {
	$root: &;

	&__main {
		background: white;
	}

	&--success {
		.AuthLayout__header__bottom {
			text-align: center;
			padding: 70px 0;
		}
	}
}