@import "scss/global/base/variables.scss";

.AuthModal.JOIN_BUSINESS .AuthModal__content,
.AuthModal.REGISTRATION_BUSINESS .AuthModal__content {
	border-radius: 0;
}

.JoinBusinessWizard {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	@media screen and (min-width:$medium-break) {
		width: 100vw;
		height: 100vh;
	}
	&__wave-initial {
		display: none;
		width: 125%;
		height: 100vh;
		position: fixed;
		right: -17.5%;
		top: 0;
		transform: scale(1);
		z-index: 98;
		@media screen and (min-width:$medium-break) {
			display: block;
		}
	}
	&__wave-terms{
		display: none;
		width: 590px;
		height: 100vh;
		position: fixed;
		left: 60vw;
		top: 0;
		transform: scale(1.3);
		z-index: 98;
		@media screen and (min-width:$medium-break){
			display: block;
			transform:scale(1.6);
		}
		@media screen and (min-width:$large-break){
			left: 65vw;
			transform:scale(2);
		}
		@media screen and (min-width:$xlarge-break) {
			transform:scale(3);
		}
	}

	&__header {
		display: flex;
		align-items: center;
		gap: $space-smallest;
		padding: $space-external $space-external 0;
		z-index: 99;
		@media screen and (min-width:$medium-break) {
			padding-bottom: $space-external;
		}
		&__logo {
			.img {
				height: 30px;
				@media screen and (min-width:$medium-break) {
					height: auto;
				}
			}
		}
		&__purpose {
			color: $font-secondary;
			font-weight:300;
			font-size: $font-size-medium;
			margin-top: 4px;
			letter-spacing:0.76px;
			@media screen and (min-width:$medium-break){
				font-size: $font-size-large
			}
		}
		&__close {
			margin-left: auto;
		}
	}
	&__main {
		flex: 1;
		height: 100%;
		overflow: auto;
		z-index: 99;
	}
}